import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import '../../Styles/Buttons.css';
import { API_URL } from '../../config';

const ChangeAdminForm = ({ phone }) => {
  const changeAdminRef = useRef();

  const [changeAdminForm, setChangeAdminForm] = useState({
    showChangeAdminForm: false,
  });
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (changeAdminRef.current && !changeAdminRef.current.contains(event.target)) {
        setChangeAdminForm(prevState => ({ ...prevState, showChangeAdminForm: false }));
      }
    };

    const handleEscapeKey = event => {
      if (event.key === 'Escape') {
        setChangeAdminForm(prevState => ({ ...prevState, showChangeAdminForm: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [changeAdminRef]);

  const handleChangeAdminClick = () => {
    setChangeAdminForm(prevState => ({ ...prevState, showChangeAdminForm: !prevState.showChangeAdminForm }));
  };
  const handleChangeNicknameClick = () => {
    setChangeAdminForm(prevState => ({ ...prevState, showChangeAdminForm: !prevState.showChangeAdminForm }));
  };

  const handleCancelClick = () => {
    setChangeAdminForm({ showChangeAdminForm: false });
  };

  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${API_URL}getAdmins`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });

      const data = response.data;
      if (data.status === 'success') {
        setAdmins(data.data);
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, [phone]);


  const handleAssignAdmin = async (adminid) => {
    try {
      const response = await axios.post(`${API_URL}assignAdmin`, {
        phone,
        admin: adminid
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });

      const data = response.data;

      if (data.status === "success") {
        toast.success(data.msg);
      } else {
        toast.error(data.msg);
      }
      fetchAdmins();
    } catch (error) {
      console.error('Error assigning admin:', error);
    }
  };

  const handleBlockAdmin = async (selectedAdmin) => {
    try {
      const response = await axios.post(`${API_URL}block_admin`, {
        id: selectedAdmin
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });

      const data = response.data;

      if (data.status === "success") {
        toast.success(data.msg);
      } else {
        toast.error(data.msg);
      }
      fetchAdmins();
    } catch (error) {
      console.error('Error blocking admin:', error);
    }
  };

  const handleUnBlockAdmin = async (selectedAdmin) => {
    try {
      const response = await axios.post(`${API_URL}unblock_admin`, {
        id: selectedAdmin
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });

      const data = response.data;

      if (data.status === "success") {
        toast.success(data.msg);
      } else {
        toast.error(data.msg);
      }
      fetchAdmins();
    } catch (error) {
      console.error('Error unblocking admin:', error);
    }
  };

  return (
    <>
      <button className='chat-title-button primary-outline-button' onClick={handleChangeAdminClick}>تغيير المسؤول</button>
      {changeAdminForm.showChangeAdminForm && <div className="overlay"></div>}
      <div ref={changeAdminRef}>
        {changeAdminForm.showChangeAdminForm && (
          <div className="change-admin-form">
            <div className='title-add'>المسؤولين</div>
            <button className="x-button" onClick={handleCancelClick}><IoClose /></button>
            <table>
              <tbody>
                {admins.map((admin, index) => (
                  <tr key={index}>
                    <td>{admin.name}</td>
                    <td>
                      {localStorage.getItem("superAdmin") === "true" ? (
                        admin.blocked ? (
                          <a className="unblocked" onClick={() => handleUnBlockAdmin(admin.id)}>فك الحظر</a>
                        ) : (
                          <a className="blue" onClick={() => handleAssignAdmin(admin.id)}>تعيين كمسؤول عن هذه المحادثة</a>
                        )
                      ) : (
                        admin.blocked ? '' : (
                          <a className="blue" onClick={() => handleAssignAdmin(admin.id)}>تعيين كمسؤول عن هذه المحادثة</a>
                        )
                      )}
                    </td>
                    <td>
                      {localStorage.getItem("superAdmin") === "true" && !admin.blocked ? (
                        <a className="blocked" onClick={() => handleBlockAdmin(admin.id)}>حظر</a>
                      ) : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default ChangeAdminForm;