import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL } from '../../config';

const AddAdminForm = () => {
  const addAdminRef = useRef();

  const [formDataCreateAdmin, setFormDataCreateAdmin] = useState({
    showAddAdminForm: false,
    name: '',
    email: '',
    password: '',
    isSuper: false,
  });

  const handleAddAdminClick = () => setFormDataCreateAdmin(prevState => ({ ...prevState, showAddAdminForm: !prevState.showAddAdminForm }));
  const handleCancelClick = () => setFormDataCreateAdmin({ showAddAdminForm: false, name: '', email: '', password: '', isSuper: false });

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const isValidPassword = (password) => /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);

  const handleSaveClick = async () => {
    const { email, name, password } = formDataCreateAdmin;
    if (!email || !name || !password) {
      toast.error('All fields are required');
      return;
    }

    if (!isValidEmail(email)) {
      toast.error('Invalid email format');
      return;
    }

    if (!isValidPassword(password)) {
      toast.error('Password must contain at least 8 characters including at least one uppercase letter, one lowercase letter, one number, and one special character');
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}create_admin`,
        formDataCreateAdmin,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const data = response.data;
      if (data.status === 'success') {
        toast.success('Admin account created successfully');
      } else if (data.msg.sqlMessage.includes('Duplicate')) {
        toast.error('Email already exists');
      } else {
        toast.error(data.msg.sqlMessage || data.msg);
      }
    } catch (error) {
      console.error('Error assigning admin:', error);
      toast.error('An error occurred while creating admin account');
    }
  };

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&';
    const passwordLength = 12;
    let password = '';

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }

    return password;
  };

  const handleGeneratePasswordClick = () => {
    const randomPassword = generateRandomPassword();
    setFormDataCreateAdmin(prevState => ({ ...prevState, password: randomPassword }));
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (addAdminRef.current && !addAdminRef.current.contains(event.target)) {
        setFormDataCreateAdmin(prevState => ({ ...prevState, showAddAdminForm: false }));
      }
    };

    const handleEscapeKey = event => {
      if (event.key === 'Escape') {
        setFormDataCreateAdmin(prevState => ({ ...prevState, showAddAdminForm: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <>
      {formDataCreateAdmin.showAddAdminForm && <div className="overlay" />}
      <button className="header-button primary-outline-button" onClick={handleAddAdminClick}>اضافة مسؤول</button>
      {formDataCreateAdmin.showAddAdminForm && (
        <div className="add-admin-form" ref={addAdminRef}>
          <div className="title">اضافة مسؤول</div>
          <button className="x-button" onClick={handleCancelClick}><IoClose /></button>
          <input
            type="text"
            placeholder="الاسم"
            value={formDataCreateAdmin.name}
            onChange={(e) => setFormDataCreateAdmin({ ...formDataCreateAdmin, name: e.target.value })}
          />
          <input
            type="email"
            placeholder="الايميل"
            value={formDataCreateAdmin.email}
            onChange={(e) => setFormDataCreateAdmin({ ...formDataCreateAdmin, email: e.target.value })}
          />
          <div className="password-input-container">
            <input
              type={"text"}
              placeholder="كلمة المرور"
              value={formDataCreateAdmin.password}
              onChange={(e) => setFormDataCreateAdmin({ ...formDataCreateAdmin, password: e.target.value })}
            />
            <button className="primary-outline-button" onClick={handleGeneratePasswordClick}>انشاء كلمة مرور</button>
          </div>
          <label>
            <input
              type="checkbox"
              className='check-admin'
              checked={formDataCreateAdmin.isSuper}
              onChange={(e) => setFormDataCreateAdmin({ ...formDataCreateAdmin, isSuper: e.target.checked })}
            />
            Super Admin
          </label>
          <button className="primary-button" onClick={handleSaveClick}>حفظ</button>
          <button className="primary-outline-button" onClick={handleCancelClick}>الغاء</button>
        </div>
      )}
    </>
  );
};

export default AddAdminForm;
