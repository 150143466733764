import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CountryFlag from '../FlagComponent/CountryFlag';
import ChangeAdminForm from './ChangeAdminForm';
import AddNicknameForm from './AddNicknameForm';
import FilePreviewForm from './FilePreviewForm';
import SubmissionForm from './SubmissionForm';
import { FaCheck } from "react-icons/fa6";
import { BiCheckDouble } from "react-icons/bi";
import { MdOutlineCancel, MdOutlineEmojiEmotions } from "react-icons/md";
import { renderMessageContent } from './MessageUtils';
import '../../Styles/ChatWindow.css';
import '../../Styles/Buttons.css';
import AudioRecorder from './AudioRecorder';
import { IoSend } from "react-icons/io5";
import { API_URL } from '../../config';
import EmojiPicker from 'emoji-picker-react';
import { io } from "socket.io-client";
import ClipLoader from 'react-spinners/ClipLoader';

const ChatWindow = ({isActive}) => {
  const [messages, setMessages] = useState([]);
  const { phone } = useParams();
  const [inputMessage, setInputMessage] = useState('');
  const [isChatSelected, setIsChatSelected] = useState(false);
  const [isActiveState, setIsActiveState] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [chatSize, setChatSize] = useState(30);
  const [pageLoad, setPageload] = useState(false);
  const [pageLoadScreen, setPageloadScreen] = useState(false);

  const handleMessageChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if(scrollTop === 0 && !pageLoadScreen) {
      setPageload(true);
      setChatSize(chatSize + 10);
    }
  };

  const onEmojiClick = (emojiObject) => {
    handleMessageChange({
      target: {
        value: inputMessage + emojiObject.emoji,
      },
    });
    setIsEmojiPickerOpen(false);
  };

  const fetchMessages = useCallback(async (controller) => {
    if (!phone) {
      setIsActiveState(false);
      setIsChatSelected(false);
      setPageloadScreen(false);
      return;
    }
    try {
      const response = await axios.get(`${API_URL}getWhatsappMessages/${phone}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          amount: chatSize,
        },
        signal: controller.signal,
      });
      const data = response.data;
      if (data.status === 'success' && Array.isArray(data.data)) {
        setMessages(data.data.reverse());
        setIsChatSelected(true);
        setIsActiveState(true);
        setPageloadScreen(false);
          const container = messagesContainerRef.current;
          if(container.scrollTop === 0 && response.data.hasMore){
            messagesContainerRef.current?.scrollTo({top: 950 });
          }
      } else {
        console.warn('Unexpected data format:', data);
      }
    } catch (error) {
    }
  }, [phone,chatSize]);


  useEffect(() => {
    if (!phone) return;
    const controller = new AbortController();
    fetchMessages(controller);
    const socket = io(process.env.REACT_APP_SOCKETIO, {
      transports: ["websocket"],
      reconnection: false,
    });
    socket.on("newMessage", () => {
      fetchMessages(controller);
    })
    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });
    return () => {
      controller.abort();  
      if (socket && socket.connected) {
        socket.disconnect();
      }
    };
  }, [phone, fetchMessages]);

  useEffect(() => {
    setPageloadScreen(true);
    setChatSize(30);
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [phone]);

  const sender = useMemo(() => messages.find(message => message.sent === 0), [messages]);

  const sendMessage = async () => {
    if (inputMessage.trim() !== '') {
      try {
        await axios.post(`${API_URL}sendWhatsapp`, {
          to: phone,
          message: inputMessage
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setInputMessage('');
        await fetchMessages();
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  useEffect(() => {
    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
    const bottomScroll = scrollHeight - clientHeight;
    if(scrollTop > (bottomScroll - 800)) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    setPageload(false);
  }, [messages]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const now = new Date();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    if (now.toDateString() === date.toDateString()) {
      return `${hours}:${minutes}`;
    } else {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${hours}:${minutes} ,${day}/${month}`;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <>
      <div className={`${!isActiveState ? 'hide-mobile' : ''} chat-window-container`}>
        {!isChatSelected && (
        <div className="no-chat-selected hide-mobile">
          <p>No chat selected</p>
        </div>
      )}
      {isChatSelected && pageLoadScreen && (
        <div className="no-chat-selected hide-mobile">
          <div className='loading-container'>
              <ClipLoader />
          </div>
        </div>
      )}
      <div className={`chat-main-container ${isChatSelected && !pageLoadScreen ? '' : 'blur'}`}>
        <div className='chat-title-container'>
        {sender && (
          <>
              <div className="flag"><CountryFlag phoneNumber={`+${phone}`} /></div>
              <div className="chat-user-details">
              <div className="chat-title">{sender.nickname || sender.name}</div>
              <div className="phone-number">{sender.phone + '+'}</div>
          </div>
          </>
            )}
          <div className='form-container'>
            <ChangeAdminForm phone={phone} />
            <AddNicknameForm phone={phone} />
          </div>
        </div>
        <div className="chat-messages-container">
          <div className="chat-messages" ref={messagesContainerRef} onScroll={handleScroll}>
            {pageLoad && (
              <div className='loading-container'>
                <ClipLoader />
              </div>
            )}
            {messages.map((message, index) => message.message ? (
              <div key={index} className={`message ${message.sent === 1 ? 'sent' : 'received'}`}>

                <div className="message-content" >
                  <p>
                  {renderMessageContent(message.message , message.sent)}
                  </p>
                </div>
                <div className="message-status-timestamp">
                  <div className="message-status">
                    {message.sent
                      ? message.status === 'sent'
                        ? <FaCheck className='sent-color' />
                        : message.status === 'delivered'
                          ? <BiCheckDouble className='delivered-color' />
                          : message.status === 'read'
                            ? <BiCheckDouble className='read-color' />
                            : message.status === 'failed'
                              ? <MdOutlineCancel className='failed-color' />
                              : ''
                      : ''}
                  </div>
                  <div className="timestamp">{formatDate(message.timestamp)}</div>
                </div>
              </div>
            ) : null)}
            <div id='messageRef' ref={messagesEndRef} />
          </div>
        </div>

        <div className="chat-input-container">
            <SubmissionForm name={sender?.nickname || sender?.name}/>
          <FilePreviewForm />
          <textarea
            type="text"
            placeholder="اكتب الرسالة هنا"
            onChange={handleMessageChange}
            value={inputMessage}
            onKeyDown={handleKeyDown}
          />
          <div className="emoji-picker-container">
          <MdOutlineEmojiEmotions className="icon icon-emoji" onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}/>
            {isEmojiPickerOpen && (
              <EmojiPicker onEmojiClick={onEmojiClick} />
            )}
          </div>
          <AudioRecorder phone={phone} onRecordingChange={setIsRecording} />
          {!isRecording && (
            <div className="send-icon-container">
              <IoSend className="icon icon-send-message" onClick={sendMessage} />
            </div>
          )}
        </div>
      </div>
      </div>
    </>
  );
}

export default ChatWindow;