import React, { useState, useEffect, useRef,useCallback } from 'react';
import axios from 'axios';
import { IoSearch } from 'react-icons/io5';
import AddContactForm from './AddContactForm';
import CountryFlag from '../FlagComponent/CountryFlag';
import { renderMessageContent } from './LastMessageUtils';
import ClipLoader from 'react-spinners/ClipLoader';

import '../../Styles/ChatsList.css';
import '../../Styles/Header.css';
import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";
import { BiCheckDouble } from "react-icons/bi";
import { API_URL } from '../../config';
import { io } from "socket.io-client";
import debounce from "lodash.debounce";

const fetchChats = async (controller,{ listSize, searchTerm, filterOption, setChats, setLoading,setPageload,isFilter }) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}getWhatsappNames`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: {
        amount: listSize + 20,
        search: searchTerm,
        admin: filterOption,
      },
      signal: controller.signal,
    });
    setChats(response.data.data);
    setLoading(false);
    setPageload(false);
  } catch (error) {
    if (axios.isCancel(error)) {
    } else {
      console.error('Error fetching chats:', error);
    }
  }
};

const ChatsList = ({isActive}) => {
  const [chats, setChats] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOption, setFilterOption] = useState('');
  const [isActiveState, setIsActiveState] = useState(isActive);
  const ref = useRef();
  const controllerRef = useRef(null);
  const [listSize, setListSize] = useState(10);
  const searchTermRef = useRef('');
  const filterOptionRef = useRef('');
  const listSizeRef = useRef(10);
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageload] = useState(false);
  const [activePhone, setactivePhone] = useState(0);

  const triggerFetchChats = useCallback(
  debounce(async (isFilter = false) => {  
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const timeoutId = setTimeout(() => {
    controller.abort();
  }, 5000);

  try {
    await fetchChats(controller, {
      listSize: listSizeRef.current,
      searchTerm: searchTermRef.current,
      filterOption: filterOptionRef.current,
      setChats,
      setLoading,
      setPageload,
      isFilter: isFilter,
    });
  } catch (error) {
    if (error.name === "AbortError") {
      console.warn("Fetch request was aborted");
    }
  } finally {
    clearTimeout(timeoutId);
  }
}, 500),
[]
);

  useEffect(() => {
    setPageload(true);
    triggerFetchChats(false);
  }, [listSize]);

  useEffect(() => {
    setLoading(true);
    triggerFetchChats(true);
  }, [filterOption]);

  useEffect(() => {
    setLoading(true);
    setListSize(10);
    listSizeRef.current = 10;
    triggerFetchChats(true);
  }, [searchTerm]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO, {
      transports: ["websocket"],
      reconnection: false,
    });
  
    socket.on("newMessage", () => {
      triggerFetchChats(true);
    });
  
    return () => {
      if (socket && socket.connected) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}getAdmins`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data.status === 'success') {
          const unblockedAdmins = response.data.data.filter(admin => !admin.blocked);
          setAdmins(unblockedAdmins);
        }
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    fetchAdmins();
  }, []);

  const handleSearchChange = (event) => {
    searchTermRef.current = event.target.value;
    setSearchTerm(event.target.value);
  };

  const readMessage = async (id) => {
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === id ? { ...chat, is_new: '0' } : chat
      )
    );
  
    try {
      const response = await axios.post(`${API_URL}readMessage`, { id }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.data.status !== 'success') {
        throw new Error("Failed to update read status");
      }
  
    } catch (error) {
      console.error("Error marking message as read:", error.response?.data || error.message);
      
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.id === id ? { ...chat, is_new: '1' } : chat
        )
      );
    }
  };

  useEffect(()=> {
    if(parseInt(searchTerm[0]) || searchTerm[0] === "+") {
      ref.current.dir = 'ltr';
    }
    else {
      ref.current.dir = 'rtl';
    }

  },[searchTerm])

  useEffect(() => {    
    const phone = window.location.pathname.split('/')[2];
    if (chats.length > 0 && phone) {
      const foundChat = chats.find(chat => chat.phone === phone);
      if (foundChat && foundChat.is_new === 1) {
        console.log(phone,'phone');
        readMessage(foundChat.id);
      }
    }
  }, [chats]);

  const handleFilterChange = event => {
    filterOptionRef.current = event.target.value;
    setFilterOption(event.target.value);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const now = new Date();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    if (now.toDateString() === date.toDateString()) {
      return `${hours}:${minutes}`;
    } else {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${hours}:${minutes}, ${day}/${month}`;
    }
  };

  const listPage = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const bottomScroll = scrollHeight - clientHeight;
    if(scrollTop > (bottomScroll - 100) && listSize < chats.length) {
      listSizeRef.current = listSize + 10;
      setListSize(listSize + 10);
    }
  };

  return (
    <div className={`chats-container ${!isActiveState ? 'hide-mobile' : ''}`}>
      <div className="chats-header-container">
        <div className="title">المحادثات</div>
        <AddContactForm />
      </div>

      <div className="chats-search-container">
        <input
          type="text"
          placeholder="بحث"
          ref={ref}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <IoSearch className="search-icon" />
      </div>

      <div className="chats-filter-container">
        <p>فلتر حسب المسؤول</p>
        <select className="filter-option" value={filterOption} onChange={handleFilterChange}>
          <option value="">الكل</option>
          {admins.map((admin, index) => (
            <option key={index} value={admin.id}>{admin.name}</option>
          ))}
        </select>
      </div>

      <div className="chats-list-container">
        {loading ? (
          <ClipLoader />
        ) : chats.length === 0 ? (
          <div className="no-results-message">لا يوجد نتائج</div>
        ) : (
          <div className="chats" onScroll={listPage}>
            {chats.map((chat, index) => (
              <Link key={index} to={`/chat/${chat.phone}`} className="chat-link" onClick={()=>{
              setIsActiveState(false);
              setactivePhone(chat.phone);
              if(chat.is_new === 1){
                readMessage(chat.id);
              }
              }} >
                <div className={`chat-item ${chat.phone === activePhone || chat.phone === window.location.pathname.split('/')[2] ? 'chat-item-selected' : ''} ${chat.is_new && chat.phone != activePhone || chat.phone != window.location.pathname.split('/')[2] ? 'is-new' : ''}`}>
                  <div className="flag" key={chat.id}>
                    <CountryFlag phoneNumber={`+${chat.phone}`} />
                  </div>
                  <div className="chat-info">
                    <div className="chat-list-title">{chat.nickname || chat.realname || chat.previousName || chat.phone} ({chat.adminName})</div>
                    <div className={`last-message ${chat.is_new ? 'is-new' : ''}`}>
                      {chat.sent
                        ? chat.status === 'sent'
                          ? <FaCheck className='sent-status' />
                          : chat.status === 'delivered'
                            ? <BiCheckDouble className='delivered-status' />
                            : chat.status === 'read'
                              ? <BiCheckDouble className='read-status' />
                              : ''
                        : ''} &nbsp;
                        {renderMessageContent(chat.message)}
                    </div>
                  </div>
                  <div className="last-message-info">
                    <div className="last-message-date">{formatDate(chat.timestamp)}</div>
                    {(chat.is_new === 1 && chat.phone != activePhone ) && (chat.is_new ===1 && chat.phone != window.location.pathname.split('/')[2]) ? <div className="new-message-marker"></div> : ''}
                  </div>
                </div>
              </Link>
            ))}
            {pageLoad &&
            <div className='loading-container'>
              <ClipLoader /> 
            </div>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatsList;
